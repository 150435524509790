<template>
  <div class="content">
    <div class="con">
      <div class="brand">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div>
        <div class="mask" v-if="showModal" @click="showModal = false"></div>
        <div class="pop" v-if="showModal">
          <div class="d1">
            <span>用户登录</span>
          </div>
          <div class="d2">
            <input
              @keydown.enter="login"
              class="ipt1"
              maxlength="11"
              type="text"
              placeholder="手机号/账号"
              v-model="userstate.uusername"
              style="padding-left: 15px"
            />
            <input
              @keydown.enter="login"
              class="ipt1"
              maxlength="16"
              type="password"
              placeholder="请输入密码"
              v-model="userstate.upassword"
              style="padding-left: 15px; position: relative"
            />
            <div style="display:flex;align-items:center;margin-top:15px;">
              <input
                @keydown.enter="register"
                class="ipt2"
                type="text"
                placeholder="验证码"
                v-model="userstate.uimagecode"
                style="padding-left: 15px;margin-top:0px;"
              />
              <img class="codeImg" :src="code_img" @click="updatacodeImg()" alt="" />
            </div>
            <input class="button" type="button" value="登录" @click="uLogin" />
            <div class="d2_1">
              <div class="findbox" @click="tofindCode">忘记密码？</div>
            </div>
          </div>
        </div>
      </div>

      <div class="home-item">
        <div class="home-left">
          <div class="img-box" v-if="img_arr.length > 0" @mouseover="seeImage">
            <img :src="img_arr[checkImage]" />
            <div
              class="slider"
              :style="{ top: sliderTop + 'px', left: sliderLeft + 'px' }"
            ></div>
            <div class="see-box">
              <img
                :src="img_arr[checkImage]"
                :style="{ top: sliderTop1 + 'px', left: sliderLeft1 + 'px' }"
                class="img1"
              />
            </div>
          </div>
          <div v-else class="img-box">
            <div class="inovation"></div>
          </div>
          <div class="home-tab">
            <div class="tab-arrow" @click="tabChange(0)">
              <img src="@/assets/image/jianrou_l.png" />
            </div>
            <div class="tab-mid">
              <div
                v-for="(item, index) in img_arr"
                :key="index"
                :class="{ active: checkImage == index }"
                @click="tabChange1(index)"
              >
                <img :src="item" />
              </div>
            </div>
            <div class="tab-arrow" @click="tabChange(1)">
              <img src="@/assets/image/jiantou_r.png" />
            </div>
          </div>
        </div>
        <div class="home-right">
          <div class="good-title">
            <span v-if="s_type == 1">新品</span>
            <span v-if="s_type == 2" style="background-color: rgb(212, 40, 45)"
              >热销</span
            >
            <span v-if="s_type == 3">推荐</span>
            <span v-if="!name" style="min-width: 38px; width: 38px"></span>
            <h2 v-if="name">{{ name }}</h2>
          </div>
          <div class="price">
            <div class="info">
              <p>价格:</p>
              <span>¥{{ price }}</span>
            </div>
          </div>

          <div class="sku-color" v-if="sku_list.length > 0">
            <div class="info">
              <p>颜料</p>
              <div class="color-item">
                <el-row>
                  <el-button
                    v-for="(value, index) in sku_list"
                    :key="index"
                    class="btn"
                    size="small"
                    plain
                    :disabled="value.disabled"
                    @click="handleActive(value)"
                  >
                    {{ value.name }}
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>

          <div class="sku-num">
            <div class="info">
              <p>数量</p>
              <el-input-number
                size="small"
                v-model="goodnum"
                class="number"
                :min="1"
              ></el-input-number>
            </div>
          </div>

          <div class="hr"></div>

          <div class="btn-con">
            <el-button
              type="danger"
              :disabled="status == 3"
              class="joincart"
              @click="addCart(good_id)"
              >加入购物车</el-button
            >
            <el-button
              type="danger"
              :disabled="status == 3"
              class="immbuy"
              @click="tobuy(good_id)"
              >立即购买</el-button
            >
            <div
              class="collect"
              v-if="collect_status == 0"
              @click="collect_good(good_id)"
            >
              <img src="@/assets/image/soucang.png" />
              <span>收藏</span>
            </div>
            <div class="collect" v-else @click="cancel_collect">
              <img src="@/assets/image/soucang_red.png" />
              <span>已收藏</span>
            </div>
          </div>
        </div>
      </div>
      <Shopdetail
        :related_recommendations="related_recommendations"
        :shop_list="shop_list"
        :content="content"
        :comments_num="comments_num"
      />
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, toRefs, ref } from "vue";
import Shopdetail from "./child/Shopdetail.vue";
import $ from "jquery";
import { useRoute, useRouter } from "vue-router";
import { getData } from "@/utils/req";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
export default {
  components: {
    Shopdetail,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let pc_user = ref("");
    let attribute_id = ref("");
    let goodnum = ref(1);
    let showModal = ref(false);
    const pro = reactive({
      brand_name: "",
      img_arr: [],
      name: "",
      price: "",
      photo_x: "",
      checkImage: 0,
      s_type: "",
      sku_list: [],
      status: "",
      collect_status: false,
      related_recommendations: "",
      shop_list: "",
      content: "",
      comments_num: "",
    });
    let state= reactive({
        code_img:'',
        code:''
    })

    let userstate =reactive({
        uusername:'',
        upassword:'',
        uimagecode:'',
    })
    let collect_id = ref("");
    let seeImg = reactive({
      sliderTop: "",
      sliderLeft: "",
      sliderTop1: "",
      sliderLeft1: "",
    });
    const good_id = computed(() => {
      return route.query.good_id;
    });

    onMounted(() => {
      pc_user.value = JSON.parse(localStorage.getItem("pc_user"));
      if (!pc_user.value.access_id) {
        pc_user.value.access_id = "";
      }
      if(!pc_user.value.user_name){
          showModal.value = true
      }
      axios();
      updatacodeImg()
    });
    const axios = () => {
      const data = {
        module: "app_pc",
        action: "product",
        m: "index",
        access_id: pc_user.value.access_id,
        pro_id: route.query.good_id,
      };
      getData(data).then((res) => {
        if (!pc_user.value.access_id) {
          pc_user.value.access_id = res.data.access_id;
          localStorage.setItem("pc_user", JSON.stringify(pc_user.value));
        }
        pro.collect_status = res.data.collection_status;
        pro.brand_name = res.data.pro.brand_name;
        pro.img_arr = res.data.pro.img_arr;
        pro.name = res.data.pro.name;
        pro.price = res.data.pro.price;
        pro.photo_x = res.data.pro.photo_x;
        pro.s_type = res.data.pro.s_type;
        pro.sku_list = res.data.skuBeanList;
        pro.status = res.data.pro.status;
        pro.related_recommendations = res.data.related_recommendations;
        pro.shop_list = res.data.shop_list;
        res.data.pro.content = res.data.pro.content
          .replace(
            /src/g,
            "style='width:100%!important;height: auto!important;' src"
          )
          .replace(/<table/g, `<table style="width:100%!important;"`);
        pro.content = res.data.pro.content;

        pro.comments_num = res.data.comments_num;
      });
    };
    function seeImage(e) {
      let x = e.pageX - $(".img-box").offset().left;
      let y = e.pageY - $(".img-box").offset().top;

      let left, top;

      if (x < 100) {
        left = 0;
      } else if (x > 380) {
        left = 280;
      } else {
        left = x - 100;
      }

      if (y < 100) {
        top = 0;
      } else if (y > 380) {
        top = 280;
      } else {
        top = y - 100;
      }

      seeImg.sliderTop = top;
      seeImg.sliderLeft = left;

      if (-2 * top < -480) {
        seeImg.sliderTop1 = -480;
      } else {
        seeImg.sliderTop1 = -2 * top;
      }

      if (-2 * left < -480) {
        seeImg.sliderLeft1 = -480;
      } else {
        seeImg.sliderLeft1 = -2 * left;
      }
    }
    const tabChange = (index) => {
      if (index == 0) {
        if (pro.checkImage == 0) {
          pro.checkImage = 0;
        } else {
          pro.checkImage -= 1;
          console.log(pro.checkImage, "xxx");
        }
      } else {
        if (pro.checkImage == pro.img_arr.length - 1) {
          return;
        } else {
          pro.checkImage += 1;
        }
      }
    };
    const tabChange1 = (index) => {
      pro.checkImage = index;
    };
    const collect_good = (good_id) => {
      pro.collect_status = !pro.collect_status;
      const data = {
        module: "app_pc",
        action: "addFavorites",
        m: "index",
        access_id: pc_user.value.access_id,
        pro_id: good_id,
        type: 1,
      };
      getData(data).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: res.message,
            duration: 1500,
          });
          collect_id.value = res.collection_id;
          axios();
        } else {
          ElMessage.error({
            message: "收藏失败",
            duration: 1500,
          });
        }
      });
    };
    const tobuy = (id) => {
      if (!attribute_id.value) {
        //默认取第一条规格的商品数据
        attribute_id.value = pro.sku_list[0].cid
      }
      var product = [];
      product.push({
        pid: id,
      });
      product.push({
        cid: attribute_id.value,
      });
      product.push({
        num: goodnum.value,
      });
      product = JSON.stringify(product);

      const data = {
        module: "app_pc",
        action: "product",
        m: "immediately_cart",
        access_id: pc_user.value.access_id,
        product,
      };
      getData(data).then((res) => {
        if (res.code == 200) {
          router.push({
            path: "/paydetail",
            query: {
              pid: id,
              cid: attribute_id.value,
              num: goodnum.value,
            },
          });
        } else {
          ElMessage.error({
            message: res.message,
            duration: 1500,
          });
        }
      });
    };
    //取消收藏
    const cancel_collect = () => {
      let data = {
        module: "app_pc",
        action: "addFavorites",
        m: "removeFavorites",
        access_id: pc_user.value.access_id,
        pro_id: route.query.good_id,
        type: 1,
        collection: collect_id.value,
      };
      getData(data).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: "取消成功",
            duration: 1500,
          });
          axios();
        }
      });
    };
    const addCart = (id) => {
      if (!attribute_id.value) {
        attribute_id.value = pro.sku_list[0].cid
      }
      const data = {
        module: "app_pc",
        action: "product",
        m: "add_cart",
        access_id: pc_user.value.access_id,
        pro_id: id,
        num: goodnum.value,
        attribute_id: attribute_id.value,
      };
      getData(data).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: "添加成功",
            duration: 1500,
          });
          store.dispatch("updateCart");
          localStorage.setItem("cart_id", res.data.cart_id);
        }
      });
    };

    const updatacodeImg = ()=>{
        const data={
            "module": "app_pc",
            "action": "login",
            "m": "get_code"
        }
        getData(data).then(res=>{
            state.code_img = "https://admin.iseso.net//"+res.data.code_img
            state.code = res.data.code
        })
    }
    const handleActive = (item) => {
      attribute_id.value = item.cid;
    };

    const tofindCode =()=>{
        router.push('/login/findcode')
    }
    const uLogin=()=>{
        if(!userstate.uusername){
            ElMessage({
                showClose: true,
                message: '用户名不能为空',
                type: 'error'
            })
            return;
        }
        else if(!userstate.upassword){
            ElMessage({
                showClose: true,
                message: '密码不能为空',
                type: 'error'
            })
            return;
        }
        else if(!userstate.uimagecode){
            ElMessage({
                showClose: true,
                message: '图形验证码不能为空',
                type: 'error'
            })
            return;
        }
        else if(state.code.toLocaleLowerCase() !== userstate.uimagecode.toLocaleLowerCase()){
            ElMessage({
            showClose: true,
            message: '验证码输入不正确',
            type: 'error'
        })
        userstate.uimagecode = ''
        updatacodeImg()
        return;
        }

        const data = {
            module:'app_pc',
            action:'login',
            m:'login',
            access_id: '',
            phone:userstate.uusername, 
            password:userstate.upassword, // 密码
            imgCode: userstate.uimagecode
        }
        getData(data).then(res=>{
            if(res.code !== 200){
                ElMessage({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                })
                return;
            }
            else{
                localStorage.setItem("pc_user",JSON.stringify(res.data))
                ElMessage({
                    showClose: true,
                    message: "登录成功",
                    type: 'success',
                    duration:1500
                })
                store.dispatch('updateCart')
                router.push('/')
            }
            
        })
    }
    return {
      ...toRefs(pro),
      attribute_id,
      good_id,
      goodnum,
      showModal,
      ...toRefs(seeImg),
      ...toRefs(state),
      userstate,
      seeImage,
      tabChange,
      tabChange1,
      collect_good,
      addCart,
      handleActive,
      tobuy,
      cancel_collect,
      updatacodeImg,
      tofindCode,
      uLogin
    };
  },
};
</script>
<style scoped lang="scss">
.content{
  width: 100%;
}
.content .con {
  width: 1200px;
  margin: 30px auto 0;
}
.home-item {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.home-item .home-left {
  width: 480px;
  position: relative;
  float: left;
}
.home-item .home-left .img-box {
  width: 480px;
  height: 480px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: move;
  position: relative;
}
.img-box img {
  width: 100%;
  height: 100%;
}
.img-box:hover .slider,
.img-box:hover .see-box {
  display: block;
}
.see-box {
  display: none;
  position: absolute;
  right: -500px;
  top: 0px;
  z-index: 99;
  width: 480px;
  height: 480px;
  overflow: hidden;
  background-color: #ffffff;
}
.see-box .img1 {
  position: absolute;
  width: 960px !important;
  height: 960px;
  max-width: 960px;
}
.img-box .slider {
  display: none;
  position: absolute;
  top: 0;
  width: 200px;
  height: 200px;
  background-image: url("../../assets/image/seeImgBg.png");
}
.home-item .home-tab {
  display: flex;
  align-items: center;
  width: 480px;
  height: 78px;
}
.home-item .home-tab .tab-arrow {
  position: relative;
  width: 15px;
  height: 78px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-item .home-tab .tab-mid {
  display: flex;
  align-items: center;
  width: 450px;
  margin-left: 10px;
}
.home-tab .tab-mid div {
  margin-right: 10px;
}
.home-item .home-tab .tab-mid img {
  width: 78px;
  height: 78px;
}
.active {
  border: 2px solid #d3272d;
}
.home-right {
  width: 670px;
  min-height: 570px;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.home-right .good-title {
  display: flex;
  align-items: center;
}
.good-title span {
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #ff6a30;
  font-size: 14px;
  color: #fffefe;
  margin-right: 9px;
}
.good-title h2 {
  color: #020202;
  font-weight: bold;
}

.home-right .price {
  height: 108px;
  margin-top: 24px;
  background-color: #f6f6f6;
  box-sizing: border-box;
}
.home-right .price .info {
  padding: 30px;
  display: flex;
  align-items: center;
}
.home-right .price .info p {
  color: #666;
}
.home-right .price .info span {
  font-size: 24px !important;
  line-height: 24px !important;
  font-weight: bold;
  color: #d4282d;
  margin-left: 20px;
}
.sku-color .info {
  display: flex;
  align-items: center;
  margin-top: 34px;
}
.sku-color .info p {
  font-size: 14px;
  color: #666666;
}
.sku-color .color-item {
  margin-left: 20px;
}
.el-button {
  border-radius: 0px;
}
.sku-num {
  margin-top: 15px;
}
.sku-num .info {
  display: flex;
  align-items: center;
}
.sku-num .info p {
  font-size: 14px;
  color: #666666;
}
.sku-num .number {
  margin-left: 20px;
}
.hr {
  width: 570px;
  height: 1px;
  margin-left: 13px;
  margin-top: 24px;
  background-color: #eeeeee;
}
.btn-con {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.btn-con .joincart {
  background-color: #d4282d;
  border-color: #dd6161;
  color: #fff;
  width: 168px;
  height: 50px;
}
.btn-con .joincart:hover {
  background: #dd6161;
}
.btn-con .immbuy {
  width: 168px;
  height: 50px;
  background: #fff;
  color: #f56c6c;
  border: 1px solid #dcdfe6;
}
.btn-con .immbuy:hover {
  color: #d4282d;
}
.btn-con .collect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #dddddd;
  font-size: 14px;
  color: #888888;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 15px;
}
.btn-con .collect:hover {
  background: #d3272d;
}

//遮罩屏显示
.mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.pop {
  background-color: #fff;
  position: fixed;
  top: 100px;
  left: 300px;
  width: calc(100% - 600px);
  height: calc(100% - 200px);
  z-index: 9999;
}
.container {
  width: 500px;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
}
.container .item {
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.container .item p {
  color: #020202;
  font-size: 16px;
  line-height: 18px;
}
.container .item input {
  width: 350px;
  height: 47px;
}
.deng {
  width: 210px;
  height: 50px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 50px;
  text-align: center;
  border: 1px solid #d7d7d7;
}
.d2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px auto 0;
  width: 600px;
}
.d2 .ipt1,
.ipt2,
.button {
  width: 420px;
  height: 50px;
  border: 1px solid rgba(221, 221, 221, 1);
  margin-top: 15px;
}
.d2 .ipt2 {
  width: 305px;
  margin-right: 15px;
}
.d2 .codeImg {
  width: 100px;
  height: 46px;
  border: 1px solid rgba(238, 238, 238, 1);
}
.d2 .button {
  background: #d4282d;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 40px;
  width: 420px;
  height: 47px;
  text-align: center;
}
.d2_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 420px;
  color: #999999;
  font-size: 14px;
  margin: 18px 0 50px 0;
}
.d2_1 .findbox {
  cursor: pointer;
}
.d1 {
  width: 254px;
  height: 22px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #27282e;
}
</style>