<template>
    <div class="end-box">
        <div class="dm-left">
            <div class="head">
                <div class="item">商品详情</div>
                <el-divider direction="vertical"></el-divider>
                <div class="item">商品评价({{comments_num}})</div>
            </div>
            <div class="dm-content" v-html="content" style="overflow:hidden;"></div>
        </div>
        <div class="dm-right">
            <div class="shop-head">
                <div class="top-con">
                    <img :src="shop_list.shop_logo" />
                    <div>{{shop_list.shop_name}}</div>
                    <el-button type="danger" plain class="goumai" @click="goShop(shop_list.shop_id)">进店逛逛</el-button>
                </div>
                <div class="bottom-con">
                    <div class="item">
                        <div>{{shop_list.quantity_on_sale}}</div>
                        <p>在售商品</p>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div class="item">
                        <div>{{shop_list.quantity_sold}}</div>
                        <p>已售</p>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div class="item">
                        <div>{{shop_list.collection_num}}</div>
                        <p>关注人数</p>
                    </div>
                </div>
            </div>

            <div class="good-list">
                <div class="head">
                    <i class="line left"></i>
                    <p>相关推荐</p>
                    <i class="line right"></i>
                </div>
                <div class="content">
                    <div v-for="(item,index) in related_recommendations" :key="index" @click="toDetail(item.id)">
                        <img :src="item.imgurl" />
                        <p>{{item.product_title}}</p>
                        <span>¥{{item.price}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    props:{
        content:{
            type:String,
            default:() =>{

            }
        },
        related_recommendations:{
            type:Array,
            default:()=>{
                
            }
        },
        shop_list:{
            type:Object,
            default:()=>{

            }
        },
        comments_num:{
            type:String,
            default:()=>{

            }
        }
    },
    setup(){
        const router = useRouter()
        const toDetail = (id) =>{
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                     good_id:id
                }
            });
            window.open(href, "_blank");
        }
        const goShop = (id) =>{
            router.push({
                path:'/shop',
                query:{
                    mch:id
                }
            })
        }
        return {
            toDetail,
            goShop
        }
    }
}
</script>
<style scoped>
    .end-box{
        width: 1200px;
        margin: 80px auto 0;;
        display: flex;
    }
    .dm-left{
        width: 850px;
    }
    .dm-left .head{
        display: flex;
        align-items: center;
        height: 49px;
        background-color: #F6F6F6;
        position: sticky;
        top: 0;
        z-index: 11;
    }
    .dm-left .head .item{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        font-size: 16px;
        color: #010101;
        border-bottom: 2px solid transparent;
        position: relative;
        cursor: pointer;
    }
    .dm-content{
        width: 850px;
    }
    .dm-content img{
        width: 850px;
    }
    .dm-right{
        width: 300px;
        margin-left: 50px;
    }
    .dm-right .shop-head{
        overflow: hidden;
        height: 120px;
        background-color: #FCF2F3;
        margin-bottom: 23px;
        position: sticky;
        top: 0;
        z-index: 11;
    }
    .dm-right .shop-head .top-con{
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 0px 16px;
    }
    .top-con img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .top-con div{
        font-size: 16px;
        color: #020202;
    }
    .top-con .el-button{
        width: 80px;
        height: 30px;
        background-color: transparent;
        border: 1px solid #D4282D;
        color: #D4282D;
        font-size: 14px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: transparent!important;
        border-radius: 0px;
    }
    .bottom-con{
        display: flex;
        align-items: center;
        margin:30px auto 0;
    }
    .bottom-con .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 98px;
        height: 36px;
    }
    .bottom-con .item div{
        font-size: 16px;
        line-height: 12px;
        color: #010101;
        font-weight: bold;
    }
    .bottom-con .item p{
        display: block;
        margin-top: -5px;
        font-size: 12px;
        color: #888888;
    }
    ::v-deep .el-divider--vertical {
        height: 2rem;
    }
    .good-list{
        border: 1px solid #e6e6e6;
    }
    .good-list .head{
        position: relative;
        color: #010101;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        padding: 25px 0;
        text-align: center;
    }
    .good-list .head i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 62px;
        height: 1px;
        background-color: #DDDDDD;
    }
    .good-list .head .left{
        left: 24px;
    }
    .good-list .head .right{
        right:24px;
    }
    .good-list .content{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .good-list .content div{
        width: 252px;
        height: 351px;
        margin: auto;
        overflow: hidden;
    }
    .good-list .content p{
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        color: #020202;
        width: 85%;
        margin: 20px auto 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .good-list .content span{
        display: block;
        font-size: 14px;
        line-height: 12px;
        margin-top: 12px;
        color: #D4282D;
    }
</style>